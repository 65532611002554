import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import home from './reducers/homeReducer';
import common from './reducers/commonReducer';
import booking from './reducers/bookingReducer';
import jobs from './reducers/jobsReducer';
import news from './reducers/newsReducer';
import search from './reducers/searchReducer';
import map from './reducers/mapReducer';
import register from './reducers/registerReducer';
import billing from './reducers/billingReducer';
import support from './reducers/supportReducer';
import chat from './reducers/chatReducer';
import savedPlaces from './reducers/savedPlacesReducer';
import myJobs from "./reducers/myJobsReducer";

const createRootReducer = (history) =>
	combineReducers({
		router: connectRouter(history),
		home,
		common,
		booking,
		jobs,
		news,
		search,
		map,
		register,
		billing,
		support,
		chat,
		savedPlaces,
		myJobs,
	});

export default createRootReducer;