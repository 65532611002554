import * as moment from 'moment-timezone';

export default function momentNowTimestamp() {
    return  moment().tz('GMT').format("YYYY-MM-DDThh:mm:ss.SSSZ");
}

export function momentDiffFromNow(firstTime) {
    const now = moment((new Date()).toUTCString()); //todays date
    const end = moment((new Date(firstTime)).toUTCString()); // another date
    const duration = moment.duration(now.diff(end));
    const hours = duration.asHours();
    return parseInt(hours);
}

export function momentDiffFromNowAsDays(firstTime) {
    const now = moment((new Date()).toUTCString()); //todays date
    const end = moment((new Date(firstTime)).toUTCString()); // another date
    const duration = moment.duration(now.diff(end));
    const hours = duration.asDays();
    return parseInt(hours);
}