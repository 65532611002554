import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';

import './internet-connection.css';
import ModalBody from '../Modal/ModalBody';
import { ReactComponent as CardErrorSvg } from '../../assests/images/error/no-connection.svg';
import {appInternetTryAgain} from '../../utils/redux/actions/commonActions';

function InternetConnection(props) {

    return (
        <Fragment>
            <div className="Internet-connection col-xs-12  no-padding">
                <ModalBody>
                    <div className="col-xs-12 no-padding">
                        <div className="modal-kuya-container">
                            <CardErrorSvg className="Card-error"/>
                        </div>
                    </div>
                    <div className="col-xs-12 no-padding text-center Internet-connection-title">
                        <p><strong>No Connection</strong></p>
                    </div>
                    <div className="col-xs-12 no-padding text-center message m-t-10">
                        <p>Please check your network</p>
                        <p>connection and try again.</p>
                    </div>
                    <div className="col-xs-12 p-l-5 m-t-20 Internet-connection-button-container">
                        <Button onClick={(e) => props.dispatch(appInternetTryAgain())} type="button" className="try-button">Try Again</Button>
                    </div>
                </ModalBody>
            </div>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    common: state.common,
});

export default connect(mapStateToProps)(InternetConnection);