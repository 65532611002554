/**
 * Calculate with and height of screen for putting fake marker exactly
 */
export default function calculateGoogleMapOffset () {

    return {
        left: document.getElementById('google-map') ? document.getElementById('google-map').offsetWidth / 2 - 20 : null,
        top: document.getElementById('google-map') ? document.getElementById('google-map').offsetHeight / 2 - 65 : null,
    }
}
/**
 * Calculate with and height of screen for ripple effect in job matching
 */
export function calculateGoogleMapOffsetRippleEffect () {
    return {
        left: document.getElementById('google-map') ? document.getElementById('google-map').offsetWidth / 2 - 10 : null,
        top: document.getElementById('google-map') ? document.getElementById('google-map').offsetHeight / 2 - 130 : null,
    }
}
/**
 * First map Calculate with and height of screen for putting fake marker exactly 
 */
export function calculateGoogleMapOffsetFirstTime () {

    return {
		left: document.getElementById("google-map") ? document.documentElement.clientWidth / 2 - 20 : null,
		top: document.getElementById("google-map") ? document.documentElement.clientHeight / 2 - 65 : null,
	};
}