import React from 'react';

export default function LoadingScreen() {
    return (
        <React.Fragment>
            <div className="loading-screen">
                <div className="lds-default">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            
        </React.Fragment>
    );
}
