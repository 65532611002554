import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';

import { promiseMiddleware, localStorageMiddleware } from './middleware';
import createRootReducer from './reducer';

export const history = createBrowserHistory();

const getMiddleware = () => {
  if (process.env.NODE_ENV === 'production') {
    return applyMiddleware(routerMiddleware(history), thunk, promiseMiddleware, localStorageMiddleware);
  } else {
    // Enable additional logging in non-production environments.
	  return applyMiddleware(routerMiddleware(history), thunk, promiseMiddleware, localStorageMiddleware, createLogger());
  }
};

export const store = createStore(
  createRootReducer(history), composeWithDevTools(getMiddleware()));