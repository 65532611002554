
const promiseMiddleware = store => next => action => {
    // console.log(action);
    next(action);
};

const localStorageMiddleware = store => next => action => {
    // console.log(action);
    next(action);
};

// function isPromise(v) {
//   return v && typeof v.then === 'function';
// }


export { promiseMiddleware, localStorageMiddleware }