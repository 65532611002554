import { 
    SUPPORT_WEB_WIDGET_CLOSE_LISTENER,
    SUPPORT_WEB_WIDGET_CLOSE_BY_BACK,
    SUPPORT_WEB_WIDGET_WILL_UNMOUNT
} from '../actions/supportActions';
  
    const initialState = {
        initCloseLinstener: false,
        closeByBack: false,
    };
    
export default function supportReducer(state = initialState, action) {
    switch(action.type) {
        case SUPPORT_WEB_WIDGET_CLOSE_LISTENER: 
            return {
                ...state,
                initCloseLinstener: true
            }
        case SUPPORT_WEB_WIDGET_CLOSE_BY_BACK: 
            return {
                ...state,
                closeByBack: true
            }
        case SUPPORT_WEB_WIDGET_WILL_UNMOUNT: 
            return {
                ...state,
                closeByBack: false
            }
        default:
            // ALWAYS have a default case in a reducer
            return state;
        }
}