import React, {Component} from 'react';
import { connect } from 'react-redux';

import './LoadingScreen.css';
import {appAuthRedirectCheck} from '../../utils/redux/actions/commonActions';
import LoadingScreenKuya from './loading-screen-kuya';
class Welcome extends Component {

  componentDidMount() {
    this.props.dispatch(appAuthRedirectCheck(this.props.history, this.props.common.auth));
  }

  render() {
    return (
      <LoadingScreenKuya />
    );
  }
}

const mapStateToProps = state => ({
  common: state.common,
});

export default connect(mapStateToProps)(Welcome);