import React, {Fragment} from 'react';

export default function ModalBody(props) {

    return (
        <Fragment>
            <div className="modal Modal col-xs-12  no-padding" tabIndex="-1" role="dialog">
                <div className="modal-dialog no-padding" role="document">
                    <div className="modal-content col-xs-12 no-padding">
                        {props.children}
                    </div>
                </div>
            </div>
        </Fragment>
    );
}