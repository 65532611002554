import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';

import './not-found.css';
import FixedBackground from '../fixed-background/fixed-background';
import { ReactComponent as NotFoundImg } from '../../assests/images/error/error-404.svg';
import { appGoHome } from '../../utils/redux/actions/commonActions';
import buttonTimeout from '../../utils/functions/button-timeout';
import {isSafari} from '../../utils/functions/check-browser';

function NotFound(props) {
    if (matchMedia('(display-mode: standalone)').matches && isSafari()) {
        props.dispatch(appGoHome());
    }
    return (
        <Fragment>
            <FixedBackground />
            <div className="col-xs-12 Not-found">
                <div className="col-xs-12 Not-found-svg">
                    <NotFoundImg />
                </div>
                <div className="col-xs-12 Not-found-oops">
                    <p><strong>Oops!</strong></p>
                </div>
                <div className="col-xs-12 Not-found-wrong">
                    <p>Something went wrong.</p>
                    <p>Check you connection and try again.</p>
                    <p>(404)</p>
                </div>
                <div className="col-xs-12 Not-found-button text-center m-t-30">
                    <Button onClick={(e) => buttonTimeout(() => props.dispatch(appGoHome()))} type="button" className="">Go Back To Home</Button>
                </div>
            </div>
        </Fragment>
    );
}

const mapStateToProps = state => ({
    common: state.common
});

export default connect(mapStateToProps)(NotFound);