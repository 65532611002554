import React from 'react';
import { Redirect } from 'react-router-dom';
import {connect} from 'react-redux';
import _ from 'lodash';
import {parse} from 'query-string';

import {toggleWebWidget} from '../../utils/redux/actions/supportActions';

function Notification(props) {

    let querystring = parse(window.location.search);

    if (_.has(querystring, 'invoice_id')) {
        return(<Redirect to={`/jobs/${querystring['invoice_id']}/receipt`} /> );
    } else if (_.has(querystring, 'zendesk')) {
        props.dispatch(toggleWebWidget());
        return(<Redirect to='/home' /> );
    } else if (_.has(querystring, 'unpaidjob')) {
        return(<Redirect to='/home' /> ); //@TODO: handle unpaid job
    } else if (_.has(querystring, 'userState')) {
        return(<Redirect to='/home' /> );
    } else {
        return(<Redirect to='/home' /> );
    }
    
}

const mapStateToProps = state => ({
    common: state.common,
});

export default connect(mapStateToProps)(Notification);