import {
    sessionStorageSetUpdatedLocation
} from '../../../../functions/session-storage';
import {
    localStorageSetUpdatedLocation
} from '../../../../functions/local-storage';

export const updatedLocationStillHere = () => {
    return (dispatch, getState) => {
        sessionStorageSetUpdatedLocation();
        localStorageSetUpdatedLocation();
    }
}

export const updatedLocationUpdateUpdatedLocation = () => {
    return (dispatch, getState) => {
        sessionStorageSetUpdatedLocation();
        localStorageSetUpdatedLocation();
    }
}